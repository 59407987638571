import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AuthProvider } from './auth';
import RequireAuth from './components/RequireAuth';
import Layout from './layouts/Layout';

import EmailConfirmationPage from './pages/EmailConfirmationPage';
import ErrorPage from './pages/ErrorPage';
import HomePage from './pages/HomePage';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import PasswordResetPage from './pages/PasswordResetPage';
import PasswordResetRequestPage from './pages/PasswordResetRequestPage';
import RegisterPage from './pages/RegisterPage';
import ResumePage from './pages/ResumePage';
import ThankYouPage from './pages/ThankYouPage';

const router = createBrowserRouter([
    {
        element: <AuthProvider />,
        children: [
            {
                element: <Layout />,
                errorElement: <ErrorPage />,
                children: [
                    {
                        path: '/',
                        element: <RequireAuth>
                            <HomePage />
                        </RequireAuth>
                    },
                    {
                        path: 'resume/:resumeId',
                        element: <RequireAuth>
                            <ResumePage />
                        </RequireAuth>
                    },
                    {
                        path: '/purchase-complete',
                        element: <RequireAuth>
                            <ThankYouPage />
                        </RequireAuth>
                    },
                    {
                        path: '/login',
                        element: <LoginPage />
                    },
                    {
                        path: '/register',
                        element: <RegisterPage />
                    },
                    {
                        path: '/confirm-email/:emailConfirmationToken?',
                        element: <EmailConfirmationPage />
                    },
                    {
                        path: '/reset-password/request',
                        element: <PasswordResetRequestPage />
                    },
                    {
                        path: '/reset-password/confirm',
                        element: <PasswordResetPage />
                    },
                    {
                        path: '*',
                        element: <NotFoundPage />
                    }
                ]
            },
            {
                path: '/welcome',
                element: <LandingPage />,
                errorElement: <ErrorPage />
            }
        ]
    }
]);

const App = () => {

    return (
        <RouterProvider router={router} />
    );

}

export default App;
